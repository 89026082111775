import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { BiBible } from 'react-icons/bi'
import { FaFacebook, FaRegCalendarAlt, FaRegMap, FaShare } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLinkButton from '../components/DashboardLinkButton'
import Header from '../components/Header'
import Overlay from '../components/Overlay'
import { getCalendar } from '../features/calendarSlice'
import flyer2 from '../img/Gebetswochenende_Bundestagswahlen_2025_Flyer_back.jpg'
import flyer1 from '../img/Gebetswochenende_Bundestagswahlen_2025_Flyer_front.jpg'
import welcomeImage from '../img/welcome.png'

function Dashboard() {
  const { calendar, isCalendarLoading, calendarError } = useSelector((state) => state.calendar)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCalendar())
  }, [])

  const calendarStart = calendar.start ? DateTime.fromISO(calendar.start).toLocaleString() : ''
  const calendarEnd = calendar.end ? DateTime.fromISO(calendar.end).toLocaleString() : ''

  return (
    <div>
      <Header />

      <main className='flex flex-col py-8 text-center'>
        {!calendarError && !isCalendarLoading && (
          <>
            <div className='flex flex-col justify-center md:flex-row'>
              <div>
                <h1>{calendar.name}</h1>

                <span className='font-bold'>
                  vom {calendarStart} bis {calendarEnd}
                </span>
                <p>
                  Cool, dass du da bist!
                  <br />
                  Im Kalender siehst du den aktuellen Gebetskalender und kannst dich für eine oder
                  mehrere Gebetszeiten eintragen.
                </p>
              </div>
              <div className='w-full flex justify-center my-2 md:w-60'>
                <img className='h-36' src={welcomeImage} alt='24/7-Gebetswoche in Öhringen' />
              </div>
            </div>
            <hr className='my-6' />
            <div className='flex flex-wrap justify-center gap-4'>
              <DashboardLinkButton to='kalender' title='Kalender' icon={<FaRegCalendarAlt />} />
              <DashboardLinkButton
                href='https://goo.gl/maps/HyaHjrEi2Ry8pYzr9'
                title='Navigieren'
                icon={<FaRegMap />}
              />
              <DashboardLinkButton
                mail='mailto:info@gebet-fuer-hohenlohe.de?subject=Frage zur 24/7-Gebetswoche Bundestagswahl 2025'
                title='Kontakt'
                icon={<FiMail />}
              />
              <DashboardLinkButton
                href='https://www.facebook.com/dasgebetshaus/'
                title='Facebook'
                icon={<FaFacebook />}
              />
            </div>
            <hr className='my-6' />
            <div>
              <h2>Eröffnungsabend</h2>
              <div className='flex justify-center items-center'>
                <FaRegCalendarAlt className='h-5 w-5 mr-2' />
                <span>21.02. um 19 Uhr</span>
              </div>
              <p>
                Die Gebetswoche beginnt mit einem gemeinsamen Eröffnungsabend im Gebetshaus
                Öhringen.
                <br />
                Ab 22 Uhr startet dann das Gebet.
              </p>
            </div>
            <hr className='my-6' />
            <div className='self-center mx-16 bg-gray-200 p-4'>
              <div>
                Das Gebet eines Gerechten vermag viel,
                <br />
                wenn es ernstlich ist.
                <div className='flex flex-row items-center justify-end mr-8 mt-2'>
                  <BiBible className='h-5 w-5 mr-2' />
                  <span>Jakobus 5,16</span>
                </div>
              </div>
            </div>
            <hr className='my-6' />
            <div>
              <h2>Flyer</h2>
              <div className='flex justify-center items-center'>
                <FaShare className='h-5 w-5 mr-2' />
                <span>zum herunterladen und teilen</span>
              </div>
              <div className='w-full flex justify-center gap-4 mt-4'>
                <DashboardLinkButton
                  href={flyer1}
                  title='Flyer vorne'
                  img={<img src={flyer1} alt='Flyer der 24/7-Gebetswoche in Öhringen' />}
                />
                <DashboardLinkButton
                  href={flyer2}
                  title='Flyer hinten'
                  img={<img src={flyer2} alt='Flyer der 24/7-Gebetswoche in Öhringen' />}
                />
              </div>
            </div>
          </>
        )}

        {calendarError && (
          <Overlay>
            <h1>{calendarError}</h1>
            <span>Bitte lade die Seite erneut.</span>
          </Overlay>
        )}

        {isCalendarLoading && (
          <>
            <h1>Lade Kalender...</h1>
          </>
        )}
      </main>
    </div>
  )
}

export default Dashboard
